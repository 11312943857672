import axios from 'axios'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

import { S3, Api, Location } from '@/utils'

export default {
  namespaced: true,

  state: {
    moments: []
  },

  getters: {
    moments: state => state.moments,
    isEmpty: state => state.moments.length === 0,
    count: state => state.moments.length
  },

  mutations: {
    SET_MOMENTS( state, moments = [] ) {
      state.moments = moments
    },
    
    APPEND_MOMENTS( state, moments ) {
      state.moments = state.moments.concat( moments )
    },

    APPEND_MOMENT( state, moment ) {
      state.moments.unshift( moment )
    },

    UPDATE_MOMENT( state, args ) {
      const { temp, id } = args
      const index = state.moments.findIndex( m => m.id === temp )
      if ( index === -1 ) return
      Vue.set( state.moments[ index ], 'id', id )
    },

    DELETE_MOMENT( state, id ) {
      const index = state.moments.findIndex( m => m.id === id )
      if ( index === -1 ) return
      Vue.delete( state.moments, index )
    }
  },

  actions: {
    async get({ commit }, { ...args }) {
      const { offset, user } = args
      const url = Api.url( '/moments', { user, offset })

      const { data } = await axios.get( url )
      commit( args.offset ? 'APPEND_MOMENTS' : 'SET_MOMENTS', data )
      return data != null && data.length
    },

    async new({ commit, rootState, rootGetters }, { ...args }) {
      let { text, image } = args
      const { id: user, forename, surname, gender, color, icon, balance } = rootGetters[ 'Auth/me' ]
      const { latitude, longitude } = await Location.get() || rootGetters[ 'Location/location' ]
      const temp = uuid()
      const blob = URL.createObjectURL( image )

      //Add it instantly client side
      commit( 'APPEND_MOMENT', {
        id: temp,
        user,
        forename, surname, gender, color, icon,
        text, image: blob,
        createdAt: new Date()
      })

      const price = rootGetters[ 'Prices/moment' ]
      if ( price ) Vue.set( rootState.Auth, 'balance', balance - price )

      try {
        if ( image ) image = await S3.upload.image( image, 'moment' )
        const { data: id } = await axios.put( '/moments', { text, image, latitude, longitude })
        commit( 'UPDATE_MOMENT', { temp, id })
        return id
      } catch ( e ) {
        commit( 'DELETE_MOMENT', temp )
        if ( price ) Vue.set( rootState.Auth, 'balance', balance )
      }
    }
  }
}