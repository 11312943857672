import axios from 'axios'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

import { S3, Api, Location } from '@/utils'

export default {
  namespaced: true,

  state: {
    city: [],
    mine: [],
    viewed: [],
    total: null,
    note: null
  },

  getters: {
    city: state => state.city,
    isCityEmpty: state => state.city.length === 0,
    countCity: state => state.city.length,

    mine: state => state.mine,
    isMineEmpty: state => state.mine.length === 0,
    countMine: state => state.mine.length,
    total: state => state.total,

    note: state => state.note
  },

  mutations: {
    SET_CITY_NOTES( state, notes = [] ) {
      state.city = notes
    },

    SET_MY_NOTES( state, notes = [] ) {
      state.mine = notes
    },
    
    APPEND_CITY_NOTES( state, notes ) {
      state.city = state.city.concat( notes )
    },

    APPEND_MY_NOTES( state, notes ) {
      state.mine = state.mine.concat( notes )
    },

    APPEND_NOTE( state, note ) {
      state.mine.unshift( note )
    },

    UPDATE_NOTE( state, args ) {
      const { temp, id } = args
      const index = state.mine.findIndex( n => n.id === temp )
      if ( index === -1 ) return
      Vue.set( state.mine[ index ], 'id', id )
    },

    DELETE_NOTE( state, id ) {
      const index = state.mine.findIndex( n => n.id === id )
      if ( index === -1 ) return
      Vue.delete( state.mine, index )
    },

    SET_TOTAL( state, total ) {
      state.total = total
    },

    SET_NOTE( state, note ) {
      state.note = note
    },

    INCREMENT_VIEWS( state, id ) {
      const index = state.city.findIndex( f => f.id === id )
      if ( index === -1 ) return

      let views = state.city[ index ].views
      Vue.set( state.city[ index ], 'views', ++views )
    }
  },

  actions: {
    async getCity({ commit, rootGetters }, { ...args }) {
      const { latitude, longitude } = await Location.get() || rootGetters[ 'Location/location' ]
      const { offset } = args
      const city = rootGetters[ 'Location/id' ]
      const url = Api.url( '/notes', { city, latitude, longitude, offset })

      const { data } = await axios.get( url )
      commit( offset ? 'APPEND_CITY_NOTES' : 'SET_CITY_NOTES', data )
      return data != null && data.length
    },

    async getMine({ commit }, { ...args }) {
      const { offset } = args
      const url = Api.url( '/notes', { offset })

      const { data } = await axios.get( url )
      commit( offset ? 'APPEND_MY_NOTES' : 'SET_MY_NOTES', data )
      return data != null && data.length
    },

    async new({ commit, rootState, rootGetters }, { ...args }) {
      let { text, image, sketch } = args
      const { latitude, longitude } = await Location.get() || rootGetters[ 'Location/location' ]
      const { id: user, balance } = rootGetters[ 'Auth/me' ]
      const temp = uuid()
      const city = rootGetters[ 'Location/id' ]
      const code = rootGetters[ 'Location/code' ]
      const country = rootGetters[ 'Location/country' ]
      const coordinates = { x: latitude, y: longitude }

      //Add it instantly client side
      commit( 'APPEND_NOTE', {
        id: temp,
        user,
        country, code, city, coordinates,
        text, image, sketch,
        views: 0,
        createdAt: new Date()
      })

      const price = rootGetters[ 'Prices/note' ]
      if ( price ) Vue.set( rootState.Auth, 'balance', balance - price )

      try {
        if ( image ) image = await S3.upload.image( image, 'note' )
        if ( sketch ) sketch = await S3.upload.image( sketch, 'note' )
        const { data: id } = await axios.put( '/notes', { city, latitude, longitude, text, image, sketch })
        commit( 'UPDATE_NOTE', { temp, id })
        return id
      } catch ( e ) {
        commit( 'DELETE_NOTE', temp )
        if ( price ) Vue.set( rootState.Auth, 'balance', balance )
      }
    },

    async count({ commit }) {
      const { data } = await axios.get( '/notes/count' )
      commit( 'SET_TOTAL', data )
      return data
    },

    async read({ commit, rootGetters }, id ) {
      const { latitude, longitude } = await Location.get() || rootGetters[ 'Location/location' ]

      const url = Api.url( `/notes/${ id }`, { latitude, longitude })
      const { data } = await axios.get( url )

      commit( 'SET_NOTE', data )
      commit( 'INCREMENT_VIEWS', data.id )
      
      return data
    }
  }
}