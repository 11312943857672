import axios from 'axios'
import _ from 'lodash'

import { S3, Storage } from '@/utils'

export default {
  namespaced: true,

  state: {
    id: null,
    secret: null,
    balance: null,
    forename: null,
    surname: null,
    email: null,
    gender: null,
    color: null,
    image: null,
    icon: null,
    bio: null
  },

  getters: {
    id: state => state.id,
    secret: state => state.secret,
    balance: state => state.balance,
    forename: state => state.forename,
    surname: state => state.surname,
    email: state => state.email,
    gender: state => state.gender,
    color: state => state.color,
    image: state => state.image,
    icon: state => state.icon,
    bio: state => state.bio,
    me: state => state
  },

  mutations: {
    SET_SECRET( state, secret ) {
      state.secret = secret
    },

    SET_USER( state, user ) {
      Object.assign( state, { ...state, ...user })
    }
  },

  actions: {
    async generate({ commit }) {
      const { data } = await axios.put( '/users' )
      const { secret } = data

      Storage.set( 'secret', secret )

      commit( 'SET_SECRET', secret )
      commit( 'SET_USER', data )

      return data
    },

    async me({ commit }) {
      const { data } = await axios.get( '/users' )
      commit( 'SET_USER', data )
      return data
    },

    async edit({ commit, getters }, { ...args }) {
      const temp = { ...getters.me }
      commit( 'SET_USER', args )
      
      try {
        if ( args.image ) args.image = await S3.upload.image( args.image, 'user' )
        const { data } = await axios.patch( '/users', args )
        return data
      } catch ( e ) {
        commit( 'SET_USER', _.pick( temp, Object.keys( args )))
      }
    },

    secret({ commit }) {
      const secret = Storage.get( 'secret' )
      if ( secret ) commit( 'SET_SECRET', secret )
      return secret
    }
  }
}