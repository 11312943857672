<template>
  <div class="navigation">

    <nav class="mobile"><Link v-for="( link, index ) in mobile" :key="index" :link="link"/></nav>
    <nav class="tablet"><Link v-for="( link, index ) in tablet" :key="index" :link="link"/></nav>
    <nav class="desktop"><Link v-for="( link, index ) in desktop" :key="index" :link="link"/></nav>

  </div>
</template>

<script>
import Link from '@/components/navigation/bar/Link.vue'

export default {
  name: 'NavigationBar',

  components: {
    Link
  },

  computed: {
    mobile() {
      return this.construct( this.$_.get( this.$router.options.navigation, 'mobile' ))
    },

    tablet() {
      return this.construct( this.$_.get( this.$router.options.navigation, 'tablet' ))
    },

    desktop() {
      return this.construct( this.$_.get( this.$router.options.navigation, 'desktop' ))
    }
  },

  methods: {
    construct( links ) {
      return links.map( name => this.$router.options.lookup( name ))
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.navigation {
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  //background: linear-gradient( 135deg, darken( $primary-color, 6 ) 0%, $primary-color 33%, darken( $primary-color, 2 ) 66%, lighten( $primary-color, 4 ) 100% );
  background-color: $primary-color;
  
  nav {
    max-width: $max-width;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @include flex;
    flex-direction: row;

    &.mobile {
      display: none;

      @media ( max-width: $mobile ) {
        @include flex;
      }
    }

    &.tablet {
      display: none;

      @media ( max-width: $tablet ) {
        @include flex;
      }

      @media ( max-width: $mobile ) {
        display: none;
      }
    }

    &.desktop {
      @media ( max-width: $tablet ) {
        display: none;
      }
    }
  }
}
</style>
