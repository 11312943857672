import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use( VueRouter )

//Main
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import 'leaflet/dist/leaflet.css'
import axios from 'axios'

import meta from 'vue-meta'
Vue.use( meta, { refreshOnceOnNavigation: true })

const env = process.env.NODE_ENV
const production = env === 'production'

//Lodash
import _ from 'lodash'
Vue.prototype.$_ = _

//Utils
import * as utils from '@/utils'
Vue.prototype.$Utils = _.pick( utils, [ 'Validation', 'Misc', 'Location', 'Dates', 'Map', 'Filters', 'Scrolling', 'User', 'S3', 'Storage' ])

const { Api, Validation } = utils
axios.interceptors.request.use( config => {
  if ( !Validation.url( config.url )) config.url = Api.url( config.url )

  //Add token to every api request
  const token = store.getters[ 'Auth/secret' ]
  if ( !token || !Api.internal( config.url )) return config
  _.set( config, 'headers.Authorization', token )
  return config
})

//Service worker
import { register } from 'register-service-worker'
if ( navigator.serviceWorker && production ) register( '/service-worker.js' )

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h( App )
}).$mount( '#app' )