import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    markers: []
  },

  getters: {
    markers: state => state.markers
  },

  mutations: {
    SET_MARKERS( state, markers ) {
      state.markers = markers || []
    },

    ADD_MARKER( state, marker ) {
      state.markers.push( marker )
    },

    REMOVE_MARKER( state, user ) {
      state.markers = state.markers.filter( u => u.id !== user )
    }
  },

  actions: {  
    listeners({ commit, rootGetters }) {
      const socket = rootGetters[ 'Sockets/socket' ]
      if ( !socket ) return

      socket.on( 'joined', data => {
        const me = rootGetters[ 'Auth/id' ]
        const marker = _.get( data, 'user' )
        if ( marker && marker.id !== me ) commit( 'ADD_MARKER', marker )
      })

      socket.on( 'left', data => commit( 'REMOVE_MARKER', data.user ) )
      socket.on( 'initalised', data => commit( 'SET_MARKERS', data ) )
    }
  }
}
