import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

import { S3, Api, Location, Storage } from '@/utils'

export default {
  namespaced: true,

  state: {
    posts: [],
    upvotes: null,
    downvotes: null
  },

  getters: {
    posts: state => state.posts,
    isEmpty: state => state.posts.length === 0,
    count: state => state.posts.length,

    upvoted: state => id => _.includes( state.upvotes, id ),
    downvoted: state => id => _.includes( state.downvotes, id )
  },

  mutations: {
    SET_POSTS( state, posts = [] ) {
      state.posts = posts
    },
    
    APPEND_POSTS( state, posts ) {
      state.posts = state.posts.concat( posts )
    },

    APPEND_POST( state, post ) {
      state.posts.unshift( post )
    },

    INCREMENT_VOTES( state, id ) {
      const index = state.posts.findIndex( f => f.id === id )
      if ( index === -1 ) return

      let votes = state.posts[ index ].votes
      Vue.set( state.posts[ index ], 'votes', ++votes )
    },

    DECREMENT_VOTES( state, id ) {
      const index = state.posts.findIndex( f => f.id === id )
      if ( index === -1 ) return

      let votes = state.posts[ index ].votes
      Vue.set( state.posts[ index ], 'votes', --votes )
    },

    SET_UPVOTES( state, me ) {
      const upvotes = Storage.get( 'upvotes', true ) || []
      if ( upvotes.includes( me )) {
        state.upvotes = upvotes
        return
      }

      state.upvotes = [ me ]
      Storage.set( 'upvotes', [ me ])
      return
    },

    SET_DOWNVOTES( state, me ) {
      const downvotes = Storage.get( 'downvotes', true ) || []
      if ( downvotes.includes( me )) {
        state.downvotes = downvotes
        return
      }

      state.downvotes = [ me ]
      Storage.set( 'downvotes', [ me ])
      return
    },

    PUSH_UPVOTE( state, id ) {
      state.upvotes.push( id )
    },

    PUSH_DOWNVOTE( state, id ) {
      state.downvotes.push( id )
    },

    SPLICE_UPVOTE( state, id ) {
      const index = state.upvotes.findIndex( u => u === id )
      if ( index !== -1 ) state.upvotes.splice( index, 1 )
    },

    SPLICE_DOWNVOTE( state, id ) {
      const index = state.downvotes.findIndex( d => d === id )
      if ( index !== -1 ) state.downvotes.splice( index, 1 )
    },

    UPDATE_POST( state, args ) {
      const { temp, id } = args
      const index = state.posts.findIndex( p => p.id === temp )
      if ( index === -1 ) return
      Vue.set( state.posts[ index ], 'id', id )
    },

    DELETE_POST( state, id ) {
      const index = state.posts.findIndex( p => p.id === id )
      if ( index === -1 ) return
      Vue.delete( state.posts, index )
    }
  },

  actions: {
    async get({ commit, state, rootGetters }, { ...args }) {
      const city = rootGetters[ 'Location/id' ]
      const url = Api.url( '/posts', { offset: args.offset, city })

      const me = rootGetters[ 'Auth/secret' ]
      if ( state.upvotes === null ) commit( 'SET_UPVOTES', me )
      if ( state.downvotes === null ) commit( 'SET_DOWNVOTES', me )

      const { data } = await axios.get( url )
      commit( args.offset ? 'APPEND_POSTS' : 'SET_POSTS', data )
      return data != null && data.length
    },

    async new({ commit, rootGetters, rootState }, { ...args }) {
      let { text, image } = args
      const { id: user, forename, surname, gender, color, icon, balance } = rootGetters[ 'Auth/me' ]
      const { latitude, longitude } = await Location.get() || rootGetters[ 'Location/location' ]
      const temp = uuid()
      const blob = image ? URL.createObjectURL( image ) : null
      const city = rootGetters[ 'Location/id' ]

      //Add it instantly client side
      commit( 'APPEND_POST', {
        id: temp,
        user,
        forename, surname, gender, color, icon,
        city,
        text, image: blob,
        children: 0,
        votes: 0,
        createdAt: new Date()
      })

      const price = rootGetters[ 'Prices/post' ]
      if ( price ) Vue.set( rootState.Auth, 'balance', balance - price )

      try {
        if ( image ) image = await S3.upload.image( image, 'post' )
        const { data: id } = await axios.put( '/posts', { city, text, image, latitude, longitude })
        commit( 'UPDATE_POST', { temp, id })
        return id
      } catch ( e ) {
        commit( 'DELETE_POST', temp )
        if ( price ) Vue.set( rootState.Auth, 'balance', balance )
      }
    },

    async upvote({ commit }, { ...args }) {
      const { id, client } = args

      commit( 'INCREMENT_VOTES', id )
      commit( client ? 'PUSH_UPVOTE' : 'SPLICE_DOWNVOTE', id )

      try {
        const { data } = await axios.patch( `/posts/${ id }/upvote` )
        client ? Storage.push( 'upvotes', id ) : Storage.splice( 'downvotes', id )
        return data
      } catch ( e ) {
        commit( client ? 'SPLICE_UPVOTE' : 'DECREMENT_VOTES', id )
      }
    },

    async downvote({ commit }, { ...args }) {
      const { id, client } = args
      
      commit( 'DECREMENT_VOTES', id )
      commit( client ? 'PUSH_DOWNVOTE' : 'SPLICE_UPVOTE', id )

      try {
        const { data } = await axios.patch( `/posts/${ id }/downvote` )
        client ? Storage.push( 'downvotes', id ) : Storage.splice( 'upvotes', id )
        return data
      } catch ( e ) {
        commit( client ? 'SPLICE_DOWNVOTE' : 'INCREMENT_VOTES', id )
      }
    }
  }
}