export default {
  get,

  set,

  push: ( name, value ) => {
    const arr = get( name, true ) || []
    if ( !arr.includes( value )) arr.push( value )
    return set( name, arr )
  },

  splice: ( name, id ) => {
    const arr = get( name, true ) || []
    const index = arr.findIndex( i => i === id )
    if ( index === -1 ) return

    arr.splice( index, 1 )
    return set( name, arr )
  },

  clear: () => localStorage.clear()
}

function get( name, parse = false ) {
  const value = localStorage.getItem( name )
  if ( !parse ) return value || null

  try {
    return JSON.parse( value )
  } catch ( e ) { console.log( e ) }

  return value
}

function set( name, value ) {
  if ( typeof value !== 'string' ) value = JSON.stringify( value )
  return localStorage.setItem( name, value )
}