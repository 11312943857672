<template>
  <router-link class="navigation-link" @click.native="navigate" :title="label" :class="{ active }" :to="link">
    
    <img :src="icon.src" :alt="icon.alt"/>
    <p>{{ label }}</p>
  
  </router-link>
</template>

<script>
export default {
  name: 'NavigationCompactLink',

  props: {
    link: Object,
    close: Function
  },

  computed: {
    active() {
      const destination = this.$route.meta.destination
      if ( destination === this.name ) return true
      return this.pages.includes( destination )
    },

    name() {
      return this.$_.get( this.link, 'name' )
    },

    label() {
      return this.$_.get( this.link, 'meta.title.long' )
    },

    icon() {
      return this.$_.get( this.link, 'meta.icon' )
    },

    pages() {
      return this.$_.get( this.link, 'meta.pages' ) || []
    }
  },

  methods: {
    navigate() {
      if ( this.link.name === this.$route.name ) this.close()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.navigation-link {
  width: auto;
  padding: 16px 0;
  text-transform: uppercase;
  position: relative;
  @include flex;

  //Text
  p {
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $white;
  }

  //Underline effect when active
  @mixin underline() {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
  }

  //Icon
  img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }
  
  &.active {
    p::before {
      @include underline;
    }
  }

  &:not( .active ) {
    p::before {
      //On hover, animate the underline
      @include underline;
      visibility: hidden;
      transform: scaleX( 0 );
      transition: all 0.15s ease-in-out 0s;
    }

    &:hover {
      p::before {
        visibility: visible;
        transform: scaleX( 1 );
      }
    }
  }
}
</style>
