import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

import Api from '@/utils/api'

export default {
  namespaced: true,

  state: {
    messages: []
  },

  getters: {
    messages: state => state.messages,
    count: state => state.messages.length
  },

  mutations: {
    SET_MESSAGES( state, messages = [] ) {
      state.messages = messages
    },

    APPEND_MESSAGES( state, messages ) {
      state.messages = state.messages.concat( messages )
    },

    APPEND_MESSAGE( state, message ) {
      state.messages.unshift( message )
    },

    UPDATE_MESSAGE( state, args ) {
      const { temp, id } = args
      const index = state.messages.findIndex( m => m.id === temp )
      if ( index === -1 ) return
      Vue.set( state.messages[ index ], 'id', id )
    },

    DELETE_MESSAGE( state, id ) {
      const index = state.messages.findIndex( m => m.id === id )
      if ( index === -1 ) return
      Vue.delete( state.messages, index )
    }
  },

  actions: {
    async send({ commit, rootGetters }, text ) {
      const { id: user, forename, surname, gender, color, icon } = rootGetters[ 'Auth/me' ]

      const temp = uuid()
      const city = rootGetters[ 'Location/id' ]

      //Add it instantly client side
      commit( 'APPEND_MESSAGE', {
        id: temp,
        user,
        forename, surname, gender, color, icon,
        city,
        text,
        createdAt: new Date()
      })

      //Insert the message
      const res = await axios.put( `/chatroom`, { city, text }).catch( console.log )

      const id = _.get( res, 'data' )
      id ? commit( 'UPDATE_MESSAGE', { temp, id }) : commit( 'DELETE_MESSAGE', temp )
      return id
    },

    async get({ commit, rootGetters }, { ...args }) {
      const city = rootGetters[ 'Location/id' ]
      
      const url = Api.url( '/chatroom', { offset: args.offset, city })
      const { data } = await axios.get( url )

      commit( args.offset ? 'APPEND_MESSAGES' : 'SET_MESSAGES', data )
      return data != null && data.length
    }
  }
}
