export default {
  sleep: ( ms ) => {
    return new Promise( resolve => {
      setTimeout( resolve, ms )
    })
  },

  capitalise: str => {
    if ( typeof str !== 'string' ) return str
    return str.replace( /\b[a-z]/g, match => match.toUpperCase() )
  },

  camel_case: str => {
    if ( typeof str !== 'string' ) return str
    return str.toLowerCase().replace( /(?:^|\s|["'([{])+\S/g, match => match.toUpperCase() )
  },

  speak: text => {
    const Speech = window.speechSynthesis
    const message = new SpeechSynthesisUtterance( text )
    const voices = Speech.getVoices()
    message.voice = voices[ 0 ]
    Speech.speak( message )
  },

  platform: () => {
    const ua = window.navigator.userAgent
    const platform = window.navigator.platform
    
    const mac = [ 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K' ]
    const windows = [ 'Win32', 'Win64', 'Windows', 'WinCE' ]
    const ios = [ 'iPhone', 'iPad', 'iPod' ]
    
    if ( mac.includes( platform )) return 'mac'
    if ( ios.includes( platform )) return 'ios'
    if ( windows.includes( platform )) return 'windows'

    if ( /Android/.test( ua )) return 'android'
    if ( /Linux/.test( platform )) return 'linux'

    return null
  },

  isPwa: () => {
    return window.matchMedia( '(display-mode: standalone)' ).matches
  }
}