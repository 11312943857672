import VueRouter from 'vue-router'
import _ from 'lodash'

const pages = [{
  path: '/feed',
  name: 'feed',
  component: () => import( '@/views/Feed.vue' ),
  meta: {
    title: {
      short: 'Feed',
      long: 'News Feed'
    },
    description: 'Have your say on current events',
    icon: {
      src: require( '@/assets/icons/navigation/feed.svg' ),
      alt: 'Paper'
    },
    image: require( '@/assets/images/navigation/posts.png' ),
    destination: 'feed',
    header: true,
    navigation: true,
    city: true
  },
}, {
  path: '/visitors',
  name: 'visitors',
  component: () => import( '@/views/Visitors.vue' ),
  meta: {
    destination: 'visitors',
    title: 'Visitors',
    description: 'Your city\'s current & past visitors',
    icon: {
      src: require( '@/assets/icons/navigation/visitors.svg' ),
      alt: 'Map marker'
    },
    image: require( '@/assets/images/navigation/visitors.png' ),
    header: true,
    navigation: true,
    city: true
  },
}, {
  path: '/map',
  name: 'map',
  component: () => import( '@/views/Map.vue' ),
  meta: {
    title: 'Map',
    description: 'See who\'s around in your city in real time',
    icon: {
      src: require( '@/assets/icons/navigation/map.svg' ),
      alt: 'Map marker'
    },
    image: require( '@/assets/images/navigation/map.png' ),
    destination: 'map',
    header: true,
    navigation: true,
    city: true
  },
}, {
  path: '/chatroom',
  name: 'chatroom',
  component: () => import( '@/views/Chatroom.vue' ),
  meta: {
    title: {
      short: 'Chat',
      long: 'Chatroom'
    },
    description: 'Chat to people currently in your city',
    icon: {
      src: require( '@/assets/icons/navigation/chatroom.svg' ),
      alt: 'Speech bubble'
    },
    image: require( '@/assets/images/navigation/chat.png' ),
    destination: 'chatroom',
    header: true,
    navigation: true,
    city: true
  },
}, {
  path: '/conversations',
  name: 'conversations',
  component: () => import( '@/views/Conversations.vue' ),
  meta: {
    title: 'Inbox',
    description: 'Message friends & create group conversations',
    icon: {
      src: require( '@/assets/icons/navigation/inbox.svg' ),
      alt: 'User'
    },
    destination: 'conversations',
    header: true,
    navigation: true,
    city: false
  }
}, {
  path: '/conversations/:id',
  name: 'conversation',
  component: () => import( '@/views/Conversation.vue' ),
  meta: {
    title: 'Inbox',
    description: 'Message friends & send media',
    destination: 'conversations',
    header: true,
    navigation: true,
    city: false
  }
}, {
  path: '/user',
  name: 'user',
  component: () => import( '@/views/User.vue' ),
  meta: {
    title: 'Profile',
    description: 'Edit & update your profile',
    icon: {
      src: require( '@/assets/icons/navigation/profile.svg' ),
      alt: 'User'
    },
    destination: 'user',
    header: true,
    navigation: true,
    city: false
  }
}, {
  path: '/users/:id',
  name: 'users',
  component: () => import( '@/views/User.vue' ),
  meta: {
    title: 'Profile',
    description: 'Lookup & interact with users',
    destination: 'user',
    header: true,
    navigation: true,
    city: false
  }
}, {
  path: '/friends',
  name: 'friends',
  component: () => import( '@/views/Friends.vue' ),
  meta: {
    title: 'Friends',
    description: 'Manage your friends & requests',
    destination: 'friends',
    image: require( '@/assets/images/navigation/friends.png' ),
    icon: {
      src: require( '@/assets/icons/navigation/friends.svg' ),
      alt: 'Friends'
    },
    header: true,
    navigation: true,
    city: false
  }
}, {
  path: '/notes',
  name: 'notes',
  component: () => import( '@/views/Notes.vue' ),
  meta: {
    title: 'Notes',
    description: 'Drop images, drawings or notes for other people to find',
    destination: 'notes',
    icon: {
      src: require( '@/assets/icons/navigation/notes.svg' ),
      alt: 'Note'
    },
    image: require( '@/assets/images/navigation/notes.png' ),
    header: true,
    navigation: true,
    city: true
  }
}, {
  path: '/shop',
  name: 'shop',
  component: () => import( '@/views/Shop.vue' ),
  meta: {
    title: 'Shop',
    destination: 'shop',
    description: 'Purchase coins & additional features',
    icon: {
      src: require( '@/assets/icons/navigation/shop.svg' ),
      alt: 'Shop'
    },
    image: require( '@/assets/images/navigation/shop.png' ),
    header: true,
    navigation: true,
    city: false
  }
}]

//Navigation pages
const navigation = [
  {
    path: '/city',
    name: 'city',
    component: () => import( '@/views/Hub.vue' ),
    meta: {
      title: 'City',
      description: 'Intreract with locals in your city',
      destination: 'city',
      icon: {
        src: require( '@/assets/icons/navigation/city.svg' ),
        alt: 'City'
      },
      pages: [ 'feed', 'visitors' ],
      header: true,
      navigation: true,
      city: true
    }
  }, {
    path: '/explore',
    name: 'explore',
    component: () => import( '@/views/Hub.vue' ),
    meta: {
      title: 'Explore',
      description: 'Explore your city & chat to locals',
      destination: 'explore',
      icon: {
        src: require( '@/assets/icons/navigation/explore.svg' ),
        alt: 'User'
      },
      //TODO include map as a page when its finished
      pages: [ 'chatroom', 'notes' ],
      header: true,
      navigation: true,
      city: true
    }
  }, {
    path: '/home',
    name: 'home',
    component: () => import( '@/views/Home.vue' ),
    meta: {
      title: 'Home',
      description: 'Meet & chat with locals in your city',
      icon: {
        src: require( '@/assets/icons/navigation/home.svg' ),
        alt: 'Home'
      },
      destination: 'home',
      header: true,
      navigation: true,
      city: true
    },
  }
]

const legal = [{
  path: '/terms',
  name: 'terms',
  component: () => import( '@/views/Terms.vue' ),
  meta: {
    title: {
      short: 'Terms',
      long: 'Terms & Conditions'
    },
    description: 'Read our terms',
    destination: 'terms',
    header: false,
    navigation: false,
    authenticated: false
  }
}, {
  path: '/policy',
  name: 'policy',
  component: () => import( '@/views/Policy.vue' ),
  meta: {
    title: {
      short: 'Policy',
      long: 'Privacy Policy'
    },
    description: 'Read our policy',
    destination: 'policy',
    header: false,
    navigation: false,
    authenticated: false
  }
}, {
  path: '/partners/influencer',
  name: 'influencer',
  component: () => import( '@/views/Influencer.vue' ),
  meta: {
    title: 'Influencer',
    description: 'Advertisement form',
    destination: 'influencer',
    header: false,
    navigation: false,
    authenticated: false
  }
}]

//Concat the routes
const routes = [ ...pages, ...navigation, ...legal ]

//Format the routes title
const format = t => typeof t === 'string' ? { short: t, long: t } : t
routes.forEach( r => r.meta.title = format( r.meta.title ))

//Default page
const defaultPage = 'home'
const main = _.omit( routes.find( p => p.name === defaultPage ), [ 'path', 'name' ])

export default new VueRouter({
  mode: 'history',

  pages: pages,

  scrollBehavior () {
    //Resets scroll position on route change
    return {
      x: 0,
      y: 0
    }
  },

  routes: [{
      path: '/',
      name: 'root',
      ...main
    },

    ...routes, 

    {
      path: '/*',
      name: 'redirect',
      ...main
    },

    {
      path: '*',
      name: '404',
      ...main
    }
  ],

  //Lookup route via name
  lookup: name  => {
    if ( !_.isString( name )) return

    const route = routes.findIndex( route => route.name.toUpperCase() === name.toUpperCase() )
    const target = routes[ route ]

    return target || null
  },

  //Title tag for head
  title: route => {
    const title = _.get( route, 'meta.title.long' ) || 'Street Meet'
    const description = _.get( route, 'meta.description' ) || 'Meet & chat with locals in your city'
    return `${ title } - ${ description }`
  },

  navigation: {
    //Screen sizes
    mobile: [ 'city', 'explore', 'home', 'conversations', 'user' ],
    tablet: [ 'city', 'explore', 'home', 'conversations', 'user' ],
    desktop: [ 'home', 'city', 'explore', 'conversations', 'user', 'shop' ],

    //Compact menu
    compact: [ 'home', 'feed', 'chatroom', 'notes', 'visitors', 'conversations', 'user', 'friends', 'shop' ]
  }
})