import axios from 'axios'
import _ from 'lodash'
import { Location as Utils, Api } from '@/utils'


export default {
  namespaced: true,

  state: {
    id: null,
    city: null,
    country: null,
    code: null,
    population: null,
    latitude: null,
    longitude: null
  },

  getters: {
    id: state => state.id,
    city: state => state.city,
    country: state => state.country,
    code: state => state.code,
    population: state => state.population,
    latitude: state => state.latitude,
    longitude: state => state.longitude,
    location: state => {
      return {
        latitude: state.latitude,
        longitude: state.longitude
      }
    }
  },

  mutations: {
    SET_ID( state, id ) {
      state.id = id
    },

    SET_CITY( state, city ) {
      state.city = city
    },

    SET_COUNTRY( state, country ) {
      state.country = country
    },

    SET_CODE( state, code ) {
      state.code = code
    },

    SET_POPULATION( state, population ) {
      state.population = population
    },

    SET_LATITUDE( state, latitude ) {
      state.latitude = latitude
    },

    SET_LONGITUDE( state, longitude ) {
      state.longitude = longitude
    },
  },

  actions: {
    async get({ commit }) {
      const position = await Utils.get()
      if ( !position ) return

      const { latitude, longitude } = position
      commit( 'SET_LATITUDE', latitude )
      commit( 'SET_LONGITUDE', longitude )
    },

    async city({ commit, state }, { ...args }) {
      const isEmpty = o => _.isEmpty( o ) ? null : o
      const { latitude, longitude } = isEmpty( args ) || state || await Utils.get()
      
      const url = Api.url( '/cities', { latitude, longitude })
      const { data } = await axios.get( url )
  
      commit( 'SET_ID', data.id )
      commit( 'SET_COUNTRY', data.country )
      commit( 'SET_CODE', data.code )
      commit( 'SET_POPULATION', data.population )
      commit( 'SET_CITY', {
        name: data.name,
        latitude: _.get( data, 'coordinates.x' ),
        longitude: _.get( data, 'coordinates.y' )
      })

      return data
    }
  }
}