<template>
  <div class="spinner-container">
    <div class="spinner">
      <div class="spinner-head"></div>
    </div>
  </div> 
</template>

<script>
export default {
  name: 'Loading'
}

</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.spinner {
  width: 8em;
  height: 8em;
  border-top: .8em solid $primary-color;
  border-right: .8em solid transparent;
  animation: spinner 0.4s linear infinite;
  border-radius: 50%;
  margin: auto;

  //Use this to change spinner size
  font-size: 7px;

  @media ( max-width: $tablet ) {
    font-size: 6px;
  }

  @media ( max-width: $mobile ) {
    font-size: 5px;
  }

  &-container {
    @include flex-center;
  }

  &-head {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-left: 8.5em;
    margin-top: 0.5em;
    background-color: rgba( $primary-color, $opacity-low );
    z-index: 4;
  }

  @keyframes spinner {
    to { transform: rotate( 360deg ); }
  }
}
</style>