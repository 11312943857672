import L from 'leaflet'

export default {
  zoom: p => {
    if ( !p ) return 15
    return p > 1000000 ? 13 : ( p > 250000 ? 14 : ( p > 10000 ? 15 : ( p > 2500 ? 16 : 17 )))
  },

  marker: {
    user: ( color, icon, size = 40 ) => {
      return {
        icon: L.divIcon({
          className: 'map-marker-user',
          html: `<div class='pin-container' style="width: ${ size }px; height: ${ size }px"><div class="pin" style="background-color: ${ color };">${ icon }</div></div>`,
          iconSize: [ size, size * 1.2 ],
          iconAnchor: [ size / 2, size * 1.2 ]
        })
      }
    },
    
    note: ( type, size = 40 ) => {
      const icon = require( `!!raw-loader!@/assets/icons/notes/note.svg` ).default
      return {
        icon: L.divIcon({
          className: 'map-marker-note',
          html: `<div class='pin-container' style="width: ${ size }px; height: ${ size }px"><div class="pin ${ type.toLowerCase() }";">${ icon }</div></div>`,
          iconSize: [ size, size * 1.2 ],
          iconAnchor: [ size / 2, size * 1.2 ]
        })
      }
    }
  }
}