<template>
  <div id="app" class="app" :style="rows">

    <!-- Landing page -->
    <div v-if="!initalised && required" class="landing">
      <div class="landing-wrapper">

        <Logo/>
        
        <div class="landing-info">
          <button role="button" v-if="!loading" @click="initalise()" class="submit shine" :class="{ disabled: !agreed }" alt="Start">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><p>Start</p></span>
          </button>
          
          <div v-if="showTerms" class="agree">
            <label for="terms">I Agree to the 
              <router-link title="Terms &#38; Conditions" :to="{ name: 'terms' }">
                Terms &#38; Conditions
              </router-link>
            </label>
            <input type=checkbox name="terms" v-model="agreed"/>
          </div>

          <h2 v-if="!loading && error">{{ error }}</h2>

          <Loading v-if="loading"/>
          <h2 class="update" v-show="loading && !error">Almost there...</h2>

        </div>

      </div>

      <ul class="links">
        <li><router-link title="Terms" to="terms"><p>Terms</p></router-link></li>
        <li><router-link title="Privacy Policy" to="policy"><p>Privacy Policy</p></router-link></li>
      </ul>
    </div>

    <!-- Main app -->
    <Header v-if="showHeader"/>
    <router-view v-if="initalised || !required" class="app-view"></router-view>
    <Navigation v-if="showNavigation"/>

    <!-- Notify them they can download the app -->
    <AppStore v-if="showApp" :close="() => this.hideApp = true"/>

  </div>
</template>

<script>
import Logo from '@/components/landing/Banner.vue'
import Loading from '@/components/Loading.vue'
import Header from '@/components/header/Container.vue'
import Navigation from '@/components/navigation/bar/Container.vue'
import AppStore from '@/components/landing/AppStore.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',

  data() {
    return {
      loading: false,
      error: null,
      agreed: false,
      showTerms: false,
      hideApp: false
    }
  },

  components: {
    Logo,
    Loading,
    Header,
    Navigation,
    AppStore
  },

  computed: {
    ...mapGetters( 'Location', {
      city: 'id'
    }),

    ...mapGetters( 'Auth', {
      user: 'id'
    }),

    initalised() {
      return this.city && this.user && !this.error
    },

    required() {
      return !( this.$route.meta.authenticated === false )
    },

    showHeader() {
      return this.$route.meta.header && this.initalised
    },

    showNavigation() {
      return this.$route.meta.navigation && this.initalised && ( this.isPwa || !this.isPhone )
    },

    isPwa() {
      return this.$Utils.Misc.isPwa()
    },

    rows() {
      if ( !this.initalised ) return
      const header = this.showHeader ? 'auto ' : ''
      const navigation = this.showNavigation ? ' auto' : ''

      return `grid-template-rows: ${ header }1fr${ navigation };`
    },

    isPhone() {
      const platform = this.$Utils.Misc.platform()
      return platform === 'android' || platform === 'ios'
    },

    showApp() {
      return !this.hideApp & this.isPhone
    }
  },

  methods: {
    ...mapActions( 'Location', {
      getCity: 'city',
      getPosition: 'get'
    }),

    ...mapActions( 'Auth', {
      generate: 'generate',
      me: 'me',
      getSecret: 'secret'
    }),

    ...mapActions( 'Limits', {
      getLimits: 'get'
    }),

    ...mapActions( 'Prices', {
      getPrices: 'get'
    }),

    ...mapActions( 'Shop', {
      getShop: 'get'
    }),

    async initalise() {
      if ( this.loading || !this.agreed ) return
      this.error = null

      if ( !navigator.geolocation ) return this.error = 'Geolocation not supported'

      this.loading = true
      this.showTerms = false
      this.$Utils.Storage.set( 'agreed', true )

      try {
        //If a user is not found
        const deleted = async e => {
          if ( this.$_.get( e, 'response.status' ) !== 404 ) throw e
          this.$Utils.Storage.clear()
          await this.generate()
        }
        
        this.secret ? await this.me().catch( deleted ) : await this.generate()
        await this.getPosition()

        await Promise.all([
          this.getCity(),
          this.getLimits(),
          this.getPrices(),
          this.getShop()
        ])
      } catch ( e ) {
        this.error = e
      }

      this.loading = false
      Notification.requestPermission()
    }
  },

  async mounted() {
    this.secret = await this.getSecret()
    const agreed = this.$Utils.Storage.get( 'agreed', true )
    this.agreed = agreed
    this.hideApp = this.$Utils.Storage.get( 'hideAppStore', true )
    this.showTerms = !agreed
    
    //Automatically initalise the user if appropiate
    const root = location.pathname === '/'
    if ( !root && this.secret && agreed && !this.showApp ) await this.initalise()
  }
}
</script>

<style lang="scss">
@import "@/styles/_index.scss";

.app {
  width: 100%;
  background-color: $background-color;
  position: relative;
  height: 100vh;
  max-height: 100vh;
  background-image: url( '~@/assets/images/background.png' );
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  display: grid;
  overflow: hidden;

  &-view {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .landing {
    @include full-stretch;
    @include flex-center;
    padding: 32px;

    @media ( max-width: $mobile ) {
      padding: 16px;
    }

    &-wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: 32px;
      padding-bottom: 24px;

      @media ( max-width: $mobile ) {
        grid-gap: 42px;
        padding: 0;
      }
    }

    &-info {
      text-align: center;
      position: relative;
      padding-top: $page-padding;
      min-height: 130px;

      button .content {
        padding: 12px 32px;
      }
      
      //Error
      h2 {
        margin-top: $page-padding;

        &.update {
          margin: 0;
          padding-top: 28px;
          animation: fadeIn .2s forwards;
          animation-delay: 5s;
          opacity: 0;
          color: $dark-grey;
          font-size: 16px;

          @media ( max-width: $tablet ) {
            font-size: 14px;
          }
        }
      }

      .agree {
        @include flex-center;
        padding-top: 16px;
        flex-direction: row-reverse;

        label {
          padding-left: 8px;
          text-align: left;
        }

        a, label {
          font-size: 14px;
        }

        a {
          font-weight: bolder;
          cursor: pointer;
          color: $hyperlink;
          display: inline-block;
        }
      }
    }
    
    .links {
      position: absolute;
      bottom: 0;
      right: 0;

      li {
        padding: $page-padding $page-padding 4px $page-padding;
        cursor: pointer;

        &:hover p {
          color: lighten( $hyperlink, 15 );
        }

        &:last-child {
          padding: 4px $page-padding $page-padding $page-padding;
        }

        p {
          color: $hyperlink;
          text-align: right;
        }
      }
    }
  }
}

.pwa-bar {
  height: 40px;
  background-color: $primary-color;
  width: 100%;
}
</style>