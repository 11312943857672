<template>
  <div class="navigation">
    <div class="wrapper">

      <span @click.stop="close">
        <img :src="icons.cross" alt="Cross"/>
      </span>

      <nav>
        <Link v-for="( link, index ) in links" :key="index" :link="link" :close="close"/>
      </nav>

    </div>
  </div>
</template>

<script>
import Link from '@/components/navigation/compact/Link.vue'

export default {
  name: 'NavigationCompact',

  props: {
    close: Function
  },

  components: {
    Link
  },

  data() {
    return {
      icons: {
        cross: require( '@/assets/icons/shared/cross.svg' )
      }
    }
  },

  computed: {
    links() {
      return this.construct( this.$_.get( this.$router.options.navigation, 'compact' ))
    }
  },

  methods: {
    construct( links ) {
      return links.map( name => this.$router.options.lookup( name ))
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.navigation {
  @include full-stretch;
  z-index: 99;
  height: 100vh;
  background-color: $off-black;
  display: block;
  overflow: hidden;

  .wrapper {
    max-width: $max-width;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
  }

  nav {
    padding: 64px $page-padding $page-padding $page-padding;
    overflow-y: scroll;
    height: 100%;
  }

  //Cross
  span {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 12px;

    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
