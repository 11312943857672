import axios from 'axios'
import Vue from 'vue'

import { Api } from '@/utils'

export default {
  namespaced: true,

  state: {
    giftings: [],
    summary: []
  },

  getters: {
    giftings: state => state.giftings,
    summary: state => state.summary,

    isEmpty: state => state.giftings.length === 0,
    count: state => state.giftings.length
  },

  mutations: {
    SET_GIFTINGS( state, giftings = [] ) {
      state.giftings = giftings
    },
    
    APPEND_GIFTINGS( state, giftings ) {
      state.giftings = state.giftings.concat( giftings )
    },

    SET_SUMMARY( state, summary = {} ) {
      state.summary = summary
    }
  },

  actions: {
    async get({ commit }, { ...args }) {
      const url = Api.url( '/giftings', { offset: args.offset })

      const { data } = await axios.get( url )
      commit( args.offset ? 'APPEND_GIFTINGS' : 'SET_GIFTINGS', data )
      return data != null && data.length
    },

    async summary({ commit }, user ) {
      const { data } = await axios.get( `/giftings/summary/${ user }` )
      commit( 'SET_SUMMARY', data )
      return data != null && data.length
    },

    async new({ rootGetters, rootState }, { ...args }) {
      const { user, gift } = args
      
      const { price } = rootGetters[ 'Gifts/gift' ]( gift )
      const { balance } = rootGetters[ 'Auth/me' ]

      Vue.set( rootState.Auth, 'balance', balance - price )
      const refund = () => Vue.set( rootState.Auth, 'balance', balance )
      
      const { data: id } = await axios.put( `/gifts/${ gift }`, { user }).catch( refund )
      return id
    }
  }
}