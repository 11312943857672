<template>
  <div class="modal-container" @mousedown.stop="close" :class="{ hasLink }">
    <form class="landing-app modal" @mousedown.stop @submit.prevent="submit">

      <div @click.stop="submit" class="image" :style="style"></div>

      <button role="button" class="submit" alt="Download the app">
        <span class="shadow"></span>
        <span class="edge"></span>
        <span class="content"><p>Download the app!</p></span>
      </button>

      <button role="button" @click.stop="hide" alt="Hide" class="hide"><p>No and don't show again.</p></button>

    </form>
  </div>
</template>

<script>
export default {
  name: 'LandingApp',

  props: {
    close: Function
  },

  data() {
    return {
      images: {
        android: require( '@/assets/images/app/android.png' ),
        ios: require( '@/assets/images/app/ios.png' )
      },
      links: {
        android: null,
        ios: null,
      }
    }
  },

  computed: {
    style() {
      const image = this.images[ this.platform ]
      if ( !image ) return null
      return `background-image: url(${ image });`
    },

    platform() {
      return this.$Utils.Misc.platform()
    },

    hasLink() {
      return !!this.links[ this.platform ]
    }
  },

  methods: {
    submit() {
      const link = this.links[ this.platform ]
      if ( !link ) return
      window.location.replace( link )
    },

    hide() {
      this.$Utils.Storage.set( 'hideAppStore', true )
      this.close()
    },

    isAndroid() {
      return this.$Utils.Misc.platform() === 'android'
    },

    isIos() {
      return this.$Utils.Misc.platform() === 'ios'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.landing-app {
  max-width: 310px;
  min-height: unset;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: 60px auto auto;
  @include box-shadow( $shadow );
  animation: slideUp .7s, fadeIn .4s;
  margin-bottom: 120px;
  border-radius: 4px;

  @media ( max-width: $mobile ) {
    max-width: 280px;
  }

  @media ( max-width: 280px ) {
    border-radius: 0;
    width: 100%;
  }

  .image {
    width: 100%;
    @include image-center;
    background-size: contain;
    cursor: pointer;
  }

  button {
    width: 100%;
    margin-top: 24px;

    &.hide {
      all: unset;
      font-weight: bolder;
      cursor: pointer;
      @include flex-center;

      p {
        font-size: 12px;
        color: $hyperlink;
      }
    }
  }
}

.modal-container {
  background-color: rgba( $off-black, $opacity-high );

  &:not( .hasLink ) {
    display: none;
  }
}
</style>
