<template>
  <div class="banner">

      <div class="info">
        <h1>Street Meet</h1>
        <p>Meet &#38; Chat with locals in your city!</p>
      </div>

      <div class="logo"></div>

  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.banner {
  @include flex-config( false, flex-start, center );

  @media ( max-width: $mobile ) {
    flex-direction: column;
  }

  .info {
    padding: 0 32px 0 0;

    @media ( max-width: $tablet ) {
      padding: 0 16px 0 0;
    }

    @media ( max-width: $mobile ) {
      padding: 0 0 28px 0;
    }

    //Title
    h1 {
      font-size: 64px;

      @media ( max-width: $tablet ) { font-size: 56px; }
      @media ( max-width: $medium ) { font-size: 48px; }
      @media ( max-width: $mobile ) { font-size: 46px; }
      @media ( max-width: $tiny ) { font-size: 38px; }
    }

    //Description
    p {
      text-align: right;
      font-size: 18px;
      font-weight: bolder;

      @media ( max-width: $tablet ) { 
        text-align: center;
        font-size: 16px;
      }

      @media ( max-width: $medium ) { font-size: 14px; }
      @media ( max-width: $tiny ) { font-size: 13px; }
    }
  }

  .logo {
    width: 128px;
    height: 128px;
    position: relative;
    background-image: url( '~@/assets/images/logo_circle.png' );
    @include image-center;
    @include flex-center;

    @media ( max-width: $tablet ) {
      width: 112px;
      height: 112px;
    }

    @media ( max-width: $medium ) {
      width: 88px;
      height: 88px;
    }

    @media ( max-width: $mobile ) {
      width: 92px;
      height: 92px;
    }

    @media ( max-width: $tiny ) {
      width: 82px;
      height: 82px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 64%;
      height: 8%;
      border-radius: 50%;
      bottom: 0;
      box-shadow: 0 24px 22px rgba( $med-dark-grey, .5 );
    }
  }
}
</style>
