import mime from 'mime-types'
import axios from 'axios'

const api = process.env.VUE_APP_API_ROUTE

export default {
  upload: {
    image: async ( image, category ) => upload( 'image', category, image )
  },

  valid_key: key => {
    const name = key.substr( 0, key.lastIndexOf( '.' ))
    return name != null && name.length === 36 && name.match( /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i )
  }
}

async function upload( type, category, image ) {
  if ( !image || !category ) return

  const extension = ( image.type ) ? mime.extension( image.type ) : 'image/png'
  const { data } = await axios.get( `${ api }/upload/${ type }?category=${ category }&extension=${ extension }` )
  
  const { name: key, url } = data
  const headers = { 'Content-Type': 'multipart/form-data' }
  await axios.put( url, image, headers )
  return key
}