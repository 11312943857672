export default {
  infinite: scroller => {
    if ( !scroller ) return

    const offset = 140
    const { scrollHeight, offsetHeight } = scroller
    return scrollHeight > offsetHeight + offset
  },

  bottom: scroller => {
    const offset = 40
    const { scrollHeight, scrollTop, offsetHeight } = scroller
    return scrollHeight - scrollTop - offset <= offsetHeight
  },

  top: scroller => {
    const offset = 40
    const { scrollTop } = scroller
    return scrollTop <= offset
  }
}