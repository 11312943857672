import axios from 'axios'
import Api from '@/utils/api'

export default {
  namespaced: true,

  state: {
    visitors: []
  },

  getters: {
    visitors: state => state.visitors,
    isEmpty: state => state.visitors.length === 0,
    count: state => state.visitors.length
  },

  mutations: {
    SET_VISITORS( state, visitors = [] ) {
      state.visitors = visitors
    },

    APPEND_VISITORS( state, visitors = [] ) {
      state.visitors = state.visitors.concat( visitors )
    }
  },

  actions: {
    async get({ commit, rootGetters }, { ...args }) {
      const city = rootGetters[ 'Location/id' ]
      const params = { offset: args.offset, city }

      const url = Api.url( '/visitors', params )
      const { data } = await axios.get( url )
      
      commit( args.offset ? 'APPEND_VISITORS' : 'SET_VISITORS', data )
      return data != null && data.length
    }
  }
}