import axios from 'axios'

export default {
  namespaced: true,

  state: {
    id: null,
    forename: null,
    surname: null,
    gender: null,
    color: null,
    image: null,
    icon: null,
    bio: null
  },

  getters: {
    id: state => state.id,
    forename: state => state.forename,
    surname: state => state.surname,
    gender: state => state.gender,
    color: state => state.color,
    image: state => state.image,
    icon: state => state.icon,
    bio: state => state.bio,
    user: state => state
  },

  mutations: {
    SET_USER( state, user ) {
      Object.assign( state, { ...state, ...user })
    }
  },

  actions: {
    async get({ commit }, id ) {
      const { data } = await axios.get( `/users/${ id }` )
      commit( 'SET_USER', data )
      return data
    }
  }
}