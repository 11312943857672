import axios from 'axios'

export default {
  namespaced: true,

  state: {
    posts: null,
    comments: null,
    visitors: null,
    chatroom: null,
    conversations: null,
    conversation: null,
    messages: null,
    friends: null,
    user: null,
    moments: null,
    notes: null,
    giftings: null,
    s3: null
  },

  getters: {
    posts: state => state.posts,
    comments: state => state.comments,
    visitors: state => state.visitors,
    chatroom: state => state.chatroom,
    conversations: state => state.conversations,
    conversation: state => state.conversation,
    messages: state => state.messages,
    friends: state => state.friends,
    user: state => state.user,
    moments: state => state.moments,
    notes: state => state.notes,
    giftings: state => state.giftings,
    s3: state => state.s3
  },

  mutations: {
    SET_LIMITS( state, limits ) {
      const { posts, comments, visitors, chatroom, conversations, conversation, messages, friends, user, moments, notes, giftings, s3 } = limits
      
      state.posts = posts
      state.comments = comments
      state.visitors = visitors
      state.chatroom = chatroom
      state.conversations = conversations
      state.conversation = conversation
      state.messages = messages
      state.friends = friends
      state.user = user
      state.moments = moments
      state.notes = notes
      state.giftings = giftings
      state.s3 = s3
      giftings
    }
  },

  actions: {
    async get({ commit }) {
      const { data } = await axios.get( '/constants/limits' )
      commit( 'SET_LIMITS', data )
      return data
    }
  }
}