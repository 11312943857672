import axios from 'axios'

export default {
  namespaced: true,

  state: {
    products: null
  },

  getters: {
    products: state => state.products
  },

  mutations: {
    SET_PRODUCTS( state, products ) {
      state.products = products
    }
  },

  actions: {
    async get({ commit }) {
      const { data } = await axios.get( '/constants/shop' )
      commit( 'SET_PRODUCTS', data )
      return data
    },

    async stripe( _, item ) {
      const { data } = await axios.put( `/shop/${ item }/stripe` )
      return data
    },

    async paypal( _, { ...args }) {
      const { id, item } = args
      const { data } = await axios.put( `/shop/paypal`, { id, item })
      return data
    }
  }
}