import axios from 'axios'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'

import { S3, Api } from '@/utils'

export default {
  namespaced: true,

  state: {
    participants: [],
    messages: []
  },

  getters: {
    participants: state => state.participants,
    messages: state => state.messages,
    count: state => state.messages.length
  },

  mutations: {
    SET_PARTICIPANTS( state, participants = [] ) {
      state.participants = participants
    },

    APPEND_PARTICIPANTS( state, participants ) {
      state.participants = state.participants.concat( participants )
    },

    SET_MESSAGES( state, messages = [] ) {
      state.messages = messages
    },

    APPEND_MESSAGES( state, messages ) {
      state.messages = state.messages.concat( messages )
    },

    APPEND_MESSAGE( state, message ) {
      state.messages.unshift( message )
    },

    UPDATE_MESSAGE( state, args ) {
      const { temp, id } = args
      const index = state.messages.findIndex( m => m.id === temp )
      if ( index === -1 ) return
      Vue.set( state.messages[ index ], 'id', id )
    },

    DELETE_MESSAGE( state, id ) {
      const index = state.messages.findIndex( m => m.id === id )
      if ( index === -1 ) return
      Vue.delete( state.messages, index )
    }
  },

  actions: {
    async participants({ commit }, { ...args }) {
      const { conversation, offset } = args

      const url = Api.url( `/conversations/${ conversation }/participants`, { offset: args.offset })
      const { data } = await axios.get( url )

      commit( offset ? 'APPEND_PARTICIPANTS' : 'SET_PARTICIPANTS', data )
      return data != null && data.length
    },

    async messages({ commit }, { ...args }) {
      const { conversation, offset } = args

      const url = Api.url( `/conversations/${ conversation }/messages`, { offset: args.offset })
      const { data } = await axios.get( url )
      
      commit( offset ? 'APPEND_MESSAGES' : 'SET_MESSAGES', data )
      return data != null && data.length
    },

    async send({ commit, rootState, rootGetters }, { ...args }) {
      let { conversation, text, image } = args
      const { id: user, forename, surname, gender, color, icon, balance } = rootGetters[ 'Auth/me' ]
      const temp = uuid()
      const blob = image ? URL.createObjectURL( image ) : null

      //Add it instantly client side
      commit( 'APPEND_MESSAGE', {
        id: temp,
        user,
        forename, surname, gender, color, icon,
        text, image: blob,
        createdAt: new Date()
      })

      const price = rootGetters[ 'Prices/message' ]
      if ( price && image ) Vue.set( rootState.Auth, 'balance', balance - price )
      
      //Update the last message
      const conversations = rootGetters[ 'Conversations/conversations' ]
      const index = conversations.findIndex( c => c.id === conversation )
      if ( index !== -1 ) {
        const data = conversations[ index ]
        const updated = { ...data, ...{ user, text, image, updatedAt: new Date() }}
        Vue.set( rootState.Conversations.conversations, index, updated )
      }

      try {
        if ( image ) image = await S3.upload.image( image, 'message' )
        const { data: id } = await axios.put( `/conversations/${ conversation }/messages`, { text, image })
        commit( 'UPDATE_MESSAGE', { temp, id })
        return id
      } catch ( e ) {
        commit( 'DELETE_MESSAGE', temp )
        if ( price && image ) Vue.set( rootState.Auth, 'balance', balance )
      }
    }
  }
}
