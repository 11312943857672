import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import Api from '@/utils/api'

export default {
  namespaced: true,

  state: {
    comments: []
  },

  getters: {
    get: state => id => state.comments[ id ] || []
  },

  mutations: {
    SET_COMMENTS( state, args ) {
      const { post: index, comments } = args
      Vue.set( state.comments, index, comments )
    },

    APPEND_COMMENTS( state, args ) {
      const { post, comments } = args
      const all = ( state.comments[ post ] || [] ).concat( comments )
      Vue.set( state.comments, post, all )
    },

    APPEND_COMMENT( state, args ) {
      const { post, comment } = args
      const comments = [ ...( state.comments[ post ] || [] )]
      comments.unshift( comment )
      Vue.set( state.comments, post, comments )
    },

    UPDATE_COMMENT( state, args ) {
      const { post, temp, id } = args
      const comments = state.comments[ post ] || []
      const index = comments.findIndex( p => p.id === temp )
      if ( index === -1 ) return
      Vue.set( state.comments[ post ][ index ], 'id', id )
    },

    DELETE_COMMENT( state, args ) {
      const { post, id } = args
      const index = state.comments[ post ].findIndex( p => p.id === id )
      if ( index === -1 ) return
      Vue.delete( state.comments[ post ], index )
    }
  },

  actions: {
    async get({ commit }, { ...args }) {
      const { post, offset } = args
      const url = Api.url( `/posts/${ post }/comments`, { offset })
      const { data: comments } = await axios.get( url )
      commit( offset ? 'APPEND_COMMENTS' : 'SET_COMMENTS', { post, comments })
      return comments != null && comments.length
    },

    async new({ commit, rootState, rootGetters }, { ...args }) {
      const { post, text } = args
      const { id: user, forename, surname } = rootGetters[ 'Auth/me' ]
      const temp = uuid()

      //Add it instantly client side
      commit( 'APPEND_COMMENT', { post, comment: {
        id: temp,
        post,
        user, forename, surname,
        text,
        createdAt: new Date()
      }})

      //Update the posts children
      const alter = increment => {
        const feed = rootGetters[ 'Posts/posts' ] || []
        const i = feed.findIndex( p => p.id === post )
        
        if ( i !== -1 ) {
          let children = feed[ i ].children
          if ( !Number.isInteger( children )) return
          increment ? children++ : children--
          Vue.set( rootState.Posts.posts[ i ], 'children', children )
        }
      }

      const increment = () => alter( true )
      const decrement = () => alter( false )

      increment()

      //Insert the comment
      const res = await axios.put( `/posts/${ post }/comments`, { text }).catch( console.log )

      const id = _.get( res, 'data' )
      id ? commit( 'UPDATE_COMMENT', { post, temp, id }) : commit( 'DELETE_COMMENT', { post, id: temp })
      if ( !id ) decrement( false )
      return id
    }
  }
}
