import Utils from '@/utils/misc'
import { format, formatDistance, isSameMinute, isSameDay, isSameWeek, isSameYear, parseISO } from 'date-fns'

export default {
  date: ( date, prefix = false ) => {
    date = ( typeof date === 'string' ) ? parseISO( date ) : date

    const timeFormat = 'h:mm a'
    //TODO can this just be replaced with formatDistance
    const label = ( p, date, config ) => `${ prefix ? p + ' ' : '' }${ format( date, config )}`

    if ( isSameMinute( date, new Date() )) return prefix ? 'just now' : 'Just now'
    if ( isSameDay( date, new Date() )) return label( 'at', date, timeFormat )
    if ( isSameWeek( date, new Date() )) return label( 'on', date, `EEEE ${ timeFormat }` )
    if ( isSameYear( date, new Date() )) return label( 'on the', date, `do 'of' MMMM` )
    return label( 'on', date, `MMMM yyyy` )
  },

  camel_case: Utils.camel_case,

  capitalise: Utils.capitalise,

  currency: ( price, currency = 'USD' ) => {
    return price.toLocaleString( 'en-US', {
      style: 'currency',
      currency
    })
  },

  duration: date => {
    date = ( typeof date === 'string' ) ? parseISO( date ) : date
    return formatDistance( date, new Date(), { addSuffix: false })
  },

  abbriviate: number => {
    const suffixes = [ '', 'K', 'M', 'B', 'T' ]
    const suffix = Math.floor(( '' + number ).length / 3.5 )
    const value = parseFloat(( suffix != 0 ? ( number / Math.pow( 1000, suffix )) : number ).toPrecision( 2 ))
    if ( value % 1 != 0 ) return `${ value.toFixed( 1 )}${ suffixes[ suffix ] }`
    return value + suffixes[ suffix ]
  }
}