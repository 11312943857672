export default {
  date: date => {
    return date != null && date.match( /(^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$)/ )
  },

  datetime: date => {
    return date != null && date.match( /(^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$)/ )
  },

  latitude: l => {
    return l != null && !isNaN( l ) && isFinite( l ) && Math.abs( l ) <= 90;
  },

  longitude: l => {
    return l != null && !isNaN( l ) && isFinite( l ) && Math.abs( l ) <= 180;
  },

  uuid: key => {
    return key != null && key.length === 36 && key.match( /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i )
  },

  email: email => {
    return email != null && typeof email === 'string' && email.match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ )
  },

  number: number => {
    return number != null && !isNaN( number )
  },

  url: url => {
    try { new URL( url )} catch ( r ) { return false }
    return true
  }
}