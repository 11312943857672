import io from 'socket.io-client'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    socket: null,
    initalised: false,
    error: null
  },

  getters: {
    socket: state => state.socket,
    connected: state => !!_.get( state.socket, 'connected' ),
    established: state => state.socket !== null,
    initalised: state => state.initalised,
    error: state => state.error
  },

  mutations: {
    SET_SOCKET( state, socket ) {
      state.socket = socket
    },

    SET_COUNT( state, count ) {
      state.count = count
    },

    SET_INITALISED( state, initalised ) {
      state.initalised = initalised
    },

    SET_ERROR( state, error ) {
      state.error = error
    }
  },

  actions: {
    connect({ commit }) {
      const route = process.env.VUE_APP_SOCKETS_ROUTE
      console.log({route})
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IncuZGFuaWVscy5xYUB0ZXNzZWxsYXRldGVjaC5jb20iLCJzYWx0IjoiRGU677-977-977-9XHUwMDE277-9OVBcYu-_vUpw77-9XHUwMDAzIiwiaWF0IjoxNjI2NzA4NDc0fQ.MH-TjhYGVLR5OO1HjoHU-JYMbzLJo5qEc5fbBsmFdwg"
      const socket = io( route, { auth: { token }} )

      commit( 'SET_SOCKET', socket )

      socket.on( 'joined', data => commit( 'SET_COUNT', data.count ))
      socket.on( 'left', data => commit( 'SET_COUNT', data.count ))

      socket.on( 'connect', () => commit( 'SET_ERROR', null ))
      socket.on( 'disconnect', () => commit( 'SET_ERROR','You have disconnected, we are trying to reconnect you' ))

      socket.on( 'connect_error', () => commit( 'SET_ERROR', 'We are having trouble connecting you' ))
      socket.on( 'reconnect_error', () => commit( 'SET_ERROR', 'We are having trouble reconnecting you' ))
    },

    initalise({ commit, rootGetters }) {
      const socket = rootGetters[ 'Sockets/socket' ]
      if ( !socket ) return
      commit

      // const { id: room, latitude, longitude } = rootGetters[ 'Location/?' ]
      // const { id, name, color, icon } = rootGetters[ 'Auth/me' ]

      //Initalise user in city
      // socket.emit( 'initalise', { city: 1, secret: '890fd873-c2d4-4811-b256-1326739ed78a' })

      // socket.on( 'initalised', () => commit( 'SET_INITALISED', true ) )
    }
  }
}