import axios from 'axios'

export default {
  namespaced: true,

  state: {
    gifts: []
  },

  getters: {
    gifts: state => state.gifts,
    gift: state => id => state.gifts.find( g => g.id === id )
  },

  mutations: {
    SET_GIFTS( state, gifts = [] ) {
      state.gifts = gifts
    }
  },

  actions: {
    async get({ commit }) {
      const { data } = await axios.get( '/gifts' )
      commit( 'SET_GIFTS', data )
      return data
    }
  }
}