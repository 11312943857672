import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'

import Api from '@/utils/api'

export default {
  namespaced: true,

  state: {
    friends: [],
    friendship: null,
    total: null
  },

  getters: {
    friends: state => state.friends,
    friendship: state => state.friendship,
    isEmpty: state => state.friends.length === 0,
    count: state => state.friends.length,
    total: state => state.total
  },

  mutations: {
    SET_FRIENDS( state, friends = [] ) {
      state.friends = friends
    },

    APPEND_FRIENDS( state, friends = [] ) {
      state.friends = state.friends.concat( friends )
    },

    APPEND_FRIEND( state, friend ) {
      state.friends.unshift( friend )
    },

    DELETE_FRIEND( state, id ) {
      const index = state.friends.findIndex( f => f.id === id )
      if ( index === -1 ) return
      Vue.delete( state.friends, index )
    },

    SET_TOTAL( state, total ) {
      state.total = total
    },

    SET_FRIENDSHIP( state, friendship ) {
      state.friendship = friendship
    },

    UPDATE_FRIENDSHIP( state, value ) {
      const field = !Number.isInteger( value ) ? 'state' : 'id'
      Vue.set( state.friendship, field, value )
    },

    DELETE_FRIENDSHIP( state ) {
      state.friendship = null
    }
  },

  actions: {
    async get({ commit }, { ...args }) {
      const { offset, search, state } = args
      const url = Api.url( '/friends', { offset, search, state })
      const { data } = await axios.get( url )
      commit( offset ? 'APPEND_FRIENDS' : 'SET_FRIENDS', data )
      return data != null && data.length
    },

    async lookup({ commit }, user ) {
      const url = Api.url( '/friends/lookup', { user })
      const { data } = await axios.get( url )
      commit( 'SET_FRIENDSHIP', data )
      return data
    },

    async count({ commit }) {
      const { data } = await axios.get( '/friends/count' )
      commit( 'SET_TOTAL', data )
      return data
    },

    async request({ commit, rootGetters }, user ) {
      commit( 'SET_FRIENDSHIP', {
        requestor: rootGetters[ 'Auth/id' ],
        requestee: user,
        state: "AWAITING",
        createdAt: new Date()
      })

      const res = await axios.put( '/friends', { user }).catch( async e => {
        const status = _.get( e, 'response.status' )
        const data = _.get( e, 'response.data' )

        //If a user has added someone at the same time as another, accept
        if ( status === 409 ) {
          const url = Api.url( `/friends/${ data }`, { state: 'ACCEPTED'})

          commit( 'UPDATE_FRIENDSHIP', data )
          commit( 'UPDATE_FRIENDSHIP', 'ACCEPTED' )

          await axios.patch( url ).catch(() => {
            commit( 'UPDATE_FRIENDSHIP', 'AWAITING' )
          })
        }
        
        if ( status !== 409 ) commit( 'DELETE_FRIENDSHIP' )
      })

      if ( _.get( res, 'data' )) {
        commit( 'UPDATE_FRIENDSHIP', res.data )
        return res.data
      }
    },

    async update({ commit }, { ...args }) {
      const { id, state } = args
      const url = Api.url( `/friends/${ id }`, { state })
      commit( 'UPDATE_FRIENDSHIP', state )

      await axios.patch( url ).catch( async e => {
        const status = _.get( e, 'response.status' )

        if ( status === 404 ) commit( 'DELETE_FRIENDSHIP' )
        if ( status !== 404 ) commit( 'UPDATE_FRIENDSHIP', 'AWAITING' )
      })
    },

    async reject({ commit }, { ...args }) {
      const friendship = { ...args }
      const { id } = friendship

      commit( 'DELETE_FRIEND', id )
      
      await axios.patch( `/friends/${ id }`, { state: 'REJECTED' }).catch(() => {
        commit( 'APPEND_FRIEND', friendship )
      })
    },

    async accept({ commit }, { ...args }) {
      const friendship = { ...args }
      const { id } = friendship

      commit( 'DELETE_FRIEND', id )
      
      await axios.patch( `/friends/${ id }`, { state: 'ACCEPTED' }).catch(() => {
        commit( 'APPEND_FRIEND', friendship )
      })
    },

    async delete({ commit }, { ...args }) {
      const friendship = { ...args }
      const { id } = friendship

      commit( 'DELETE_FRIEND', id )
      
      await axios.delete( `/friends/${ id }` ).catch(() => {
        commit( 'APPEND_FRIEND', friendship )
      })
    }
  }
}