import Vue from 'vue'
import Vuex from 'vuex'

import Auth from '@/store/modules/auth'
import Location from '@/store/modules/location'
import User from '@/store/modules/user'
import Visitors from '@/store/modules/visitors'
import Posts from '@/store/modules/posts'
import Comments from '@/store/modules/comments'
import Friends from '@/store/modules/friends'
import Moments from '@/store/modules/moments'
import Notes from '@/store/modules/notes'
import Limits from '@/store/modules/limits'
import Prices from '@/store/modules/prices'
import Shop from '@/store/modules/shop'
import Chatroom from '@/store/modules/chatroom'
import Sockets from '@/store/modules/sockets'
import Map from '@/store/modules/map'
import Conversations from '@/store/modules/conversations'
import Conversation from '@/store/modules/conversation'
import Gifts from '@/store/modules/gifts'
import Giftings from '@/store/modules/giftings'
import Partners from '@/store/modules/partners'

Vue.use( Vuex )

export default new Vuex.Store({
  modules: {
    Auth,
    Location,
    User,
    Visitors,
    Posts,
    Comments,
    Friends,
    Moments,
    Notes,
    Limits,
    Prices,
    Shop,
    Chatroom,
    Sockets,
    Map,
    Conversations,
    Conversation,
    Gifts,
    Giftings,
    Partners
  }
})
