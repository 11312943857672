<template>
  <router-link class="navigation-link" :title="label" :class="{ active }" :to="link">
    <div class="content">
      
      <img :src="icon.src" :alt="icon.alt"/>

      <p>{{ label }}</p>

    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationBarLink',

  props: {
    link: Object
  },

  computed: {
    active() {
      const destination = this.$route.meta.destination
      if ( destination === this.name ) return true
      return this.pages.includes( destination )
    },

    name() {
      return this.$_.get( this.link, 'name' )
    },

    label() {
      return this.$_.get( this.link, 'meta.title.long' )
    },

    icon() {
      return this.$_.get( this.link, 'meta.icon' )
    },

    pages() {
      return this.$_.get( this.link, 'meta.pages' ) || []
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

$hideTextOnMobile: false;

.navigation-link {
  height: 100%;
  width: 100%;
  @include flex-center;
  position: relative;
  cursor: pointer;

  //Seperator
  &:not( :first-child ) {
    &::after {
      left: 0;
      top: 10%;
      bottom: 10%;
      height: 80%;
      width: 1px;
      background-color: $off-white;
      content: '';
      position: absolute;
    }
  }

  &:hover, &.active {
    * {
      opacity: 1;
    }
  }

  .content {
    text-align: center;
    margin-top: 1px;

    @media ( max-width: $mobile ) {
      @if $hideTextOnMobile {
        padding: 4px 0;
      }
    }
  }

  * {
    opacity: 0.8;
  }

  img {
    width: 20px;
    height: 20px;

    @media ( max-width: $mobile ) {
      @if $hideTextOnMobile {
        width: 24px;
        height: 24px;
      }
    }
  }

  p {
    font-size: 16px;
    padding-top: 2px;
    color: $off-white;
    word-break: normal;

    @media ( max-width: $mobile ) {
      font-size: 14px;
    }

    @media ( max-width: $mobile ) {
      font-size: 13px;

      @if $hideTextOnMobile {
        display: none;
      }
    }
  }
}
</style>
