import Utils from '@/utils/misc'
import S3 from '@/utils/s3'

export default {
  name: user => {
    const names = [ user.forename, user.surname ]
    const name = names.filter( Boolean ).join( ' ' )
    return Utils.capitalise( name )
  },

  image: ( image, color ) => {
    if ( !image ) return `background-color: ${ color };`
    if ( typeof image === 'object' ) image = URL.createObjectURL( image )

    const s3 = `${ process.env.VUE_APP_S3_ROUTE }/users/${ image }`
    const url = S3.valid_key( image ) ? s3 : image
    return `background-image: url(${ url }); background-color: ${ color }`
  },

  icon: icon => {
    if ( !icon ) return null
    try { return require( `!!raw-loader!@/assets/icons/profile/${ icon }.svg` ).default } catch ( e ) { console.log( e ) }
    return null
  }
}