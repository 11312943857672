import axios from 'axios'

export default {
  namespaced: true,

  actions: {
    async submit( _, fields ) {
      const { data } = await axios.post( '/partners', fields )
      return data
    }
  }
}