import axios from 'axios'

export default {
  namespaced: true,

  state: {
    post: null,
    moment: null,
    conversation: null,
    note: null,
    message: null
  },

  getters: {
    post: state => state.post,
    moment: state => state.moment,
    conversation: state => state.conversation,
    note: state => state.note,
    message: state => state.message
  },

  mutations: {
    SET_PRICES( state, prices ) {
      const { post, moment, conversation, note, message } = prices
      
      state.post = post
      state.moment = moment
      state.conversation = conversation
      state.note = note
      state.message = message
    }
  },

  actions: {
    async get({ commit }) {
      const { data } = await axios.get( '/constants/prices' )
      commit( 'SET_PRICES', data )
      return data
    }
  }
}