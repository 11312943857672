const api = process.env.VUE_APP_API_ROUTE
import _ from 'lodash'

export default {
  url: ( route, params ) => {
    const url = `${ api }${ route }`
    return params ? `${ url }${ qs( params )}` : url
  },

  qs,

  internal: url => typeof url === 'string' && url.includes( api )
}

function qs( params ) {
  if ( !params ) return
  params = _.pickBy( params, v => !_.isNil( v ))
  const value = key => typeof params[ key ] === 'object' ? JSON.stringify( params[ key ]) : params[ key ]
  const encode = key => encodeURIComponent( value( key ))

  const q = Object.keys( params ).map( k => `${ k }=${ encode( k )}`).join( '&' )
  return `?${ q }`
}