<template>
  <div class="header">
    <div class="wrapper" :class="{ compact, showCity }">

      <!-- Compact menu -->
      <span v-if="compact" @click.stop="toggle">
        <img :src="icons.menu" alt="Menu"/>
      </span>

      <Compact v-if="navigating" :close="() => this.navigating = false"/>

      <h1 :title="title">{{ title }}</h1>

      <h2 v-if="showCity" :title="city">{{ city }}</h2>
      <div v-if="showCity && flag" class="flag" :title="hover" :style="flag" alt="Flag"></div>

    </div>
  </div>
</template>

<script>
import Compact from '@/components/navigation/compact/Container.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  data() {
    return {
      navigating: false,
      image: require( '@/assets/images/app/android.png' ),
      icons: {
        menu: require( '@/assets/icons/navigation/burger.svg' )
      }
    }
  },

  components: {
    Compact
  },

  methods: {
    toggle() {
      this.navigating = !this.navigating
    }
  },
  
  computed: {
    ...mapGetters( 'Location', {
      city_: 'city',
      country: 'country',
      code: 'code'
    }),

    city() {
      return this.city_.name
    },

    title() {
      return this.$_.get( this.$route.meta, 'title.short' )
    },

    flag() {
      if ( !this.country ) return
      const flag = `${ this.code.toLowerCase() }.png`
      const url = `${ process.env.VUE_APP_S3_ROUTE }/flags/${ flag }`
      return `background-image: url(${ url });`
    },

    showCity() {
      return this.$_.get( this.$route.meta, 'city' )
    },

    hover() {
      return `${ this.country } (${ this.code })`
    },

    isPwa() {
      return this.$Utils.Misc.isPwa()
    },

    isPhone() {
      const platform = this.$Utils.Misc.platform()
      return platform === 'android' || platform === 'ios'
    },

    compact() {
      return this.isPhone && !this.isPwa
    }
  },

  watch: {
    $route() {
      this.navigating = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

@mixin menu( $size ) {
  width: $size;
  height: $size;
  min-width: $size;
  cursor: pointer;
  grid-area: menu;
  @include flex-center;

  img {
    width: 24px;
    height: 24px;
  }
}

@mixin flag( $size ) {
  width: $size;
  min-width: $size;
  height: $size;
  border-radius: 50%;
  border: 2px solid $off-white;
  background-color: $off-white;
  @include image-center;
  grid-area: flag;
}

h1, h2 {
  color: $off-white;
  text-align: left;
  @include ellipsis;
  font-size: 34px;
}

.header {
  width: 100%;
  //background: radial-gradient( ellipse at right bottom, lighten( $primary-color, 6 ) 0%, $primary-color 56%, $primary-color 100% );
  background-color: $primary-color;
  
  .wrapper {
    max-width: $max-width;
    width: 100%;
    padding: 10px $page-padding;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-areas: "title city flag";
    grid-template-columns: auto 1fr auto;
    grid-gap: 0 16px;
    .flag { @include flag( 38px );}

    @media ( max-width: $tablet ) {
      grid-gap: 0 12px;
    }

    //Normal menu
    &:not( .compact ) {
      h2 { text-align: right; }

      @media ( max-width: $tablet ) {
        h1, h2 { font-size: 26px; }
        .flag { @include flag( 34px );}
      }

      @media ( max-width: $mobile ) {
        h1, h2 { font-size: 24px; }
      }
    }

    //Compact burger menu
    &.compact {
      grid-template-areas: "menu title flag" "menu city flag";
      padding: 8px $page-padding;

      &:not( .showCity ) {
        grid-template-areas: "menu title";
        padding: 14px $page-padding;
      }

      span { @include menu( 40px );}

      h1 {
        font-size: 28px;
        line-height: 28px;
        padding: 0;
      }
      h2 { font-size: 16px; }

      @media ( max-width: $tablet ) {
        h1 { font-size: 24px; }
        h2 { font-size: 14px; }

        .flag { @include flag( 36px );}
        span { @include menu( 36px );}
      }

      @media ( max-width: $mobile ) {
        h1 { font-size: 22px; }
        h2 { font-size: 12px; }

        .flag { @include flag( 36px );}
        span { @include menu( 36px );}
      }
    }
  }
}

h1 { grid-area: title }
h2 { grid-area: city }

h1, h2 {
  color: $off-white;
  @include ellipsis;
  padding: 2px 0;
}
</style>
