import _ from 'lodash'

export default {
  get: async ( timeout = 8000 ) => {
    return _.get( await location( timeout ), 'coords' )  
  },

  //km
  distance: ( lat1, lon1, lat2, lon2 ) => {
    const p = Math.PI / 180
    const c = Math.cos
    const a = 0.5 - c(( lat2 - lat1 ) * p ) / 2 + 
      c( lat1 * p ) * c( lat2 * p ) * 
      ( 1 - c(( lon2 - lon1 ) * p )) / 2
    
    return 12742 * Math.asin( Math.sqrt( a ))
  },

  bearing: ( lat1, lon1, lat2, lon2 ) => {
    const dy = lon2 - lon1
    const dx = lat2 - lat1
    let bearing = Math.atan2( dy, dx )
    bearing *= 180 / Math.PI
    return bearing < 0 ? 360 + bearing : bearing
  },

  direction: ( degrees, accuracy = 1, abbriviated = false ) => {
    const accuracies = [
      [ 'N', 'E', 'S', 'W' ],
      [ 'N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW' ],
      [ 'N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW' ]
    ]

    const labels = accuracies[ accuracy ]
    const label = labels[ Math.round( degrees / ( 360 / labels.length )) % labels.length ]
    if ( abbriviated ) return label

    return label.replace( /N/g, 'North ' ).replace( /S/g, 'South ' ).replace( /E/g, 'East ' ).replace( /W/g, 'West ' ).slice( 0, -1 )
  }
}

function location( timeout ) {
  return new Promise(( resolve, reject ) => {
    //e.message for official error
    navigator.geolocation.getCurrentPosition( resolve, () => reject( 'User denied location' ), { timeout })
    //Fake location
    // const position = { coords: { latitude: 56.3691906, longitude: -1.4782784 }}
    // return resolve( position )
  })
}