import axios from 'axios'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import Vue from 'vue'

import Api from '@/utils/api'

export default {
  namespaced: true,

  state: {
    conversations: []
  },

  getters: {
    conversations: state => state.conversations,
    isEmpty: state => state.conversations.length === 0,
    count: state => state.conversations.length
  },

  mutations: {
    SET_CONVERSATIONS( state, conversations = [] ) {
      state.conversations = conversations
    },
    
    APPEND_CONVERSATIONS( state, conversations = [] ) {
      state.conversations = state.conversations.concat( conversations )
    },

    APPEND_CONVERSATION( state, conversation ) {
      state.conversations.unshift( conversation )
    },

    UPDATE_CONVERSATION( state, args ) {
      const { temp, id } = args
      const index = state.conversations.findIndex( p => p.id === temp )
      if ( index === -1 ) return
      Vue.set( state.conversations[ index ], 'id', id )
    },

    DELETE_CONVERSATION( state, id ) {
      const index = state.conversations.findIndex( p => p.id === id )
      if ( index === -1 ) return
      Vue.delete( state.conversations, index )
    }
  },

  actions: {
    async get({ commit }, { ...args }) {
      const { offset } = args

      const url = Api.url( '/conversations', { offset })
      const { data } = await axios.get( url )

      commit( offset ? 'APPEND_CONVERSATIONS' : 'SET_CONVERSATIONS', data )
      return data != null && data.length
    },

    async new({ rootGetters, rootState, commit }, { ...args }) {
      const { participants: ids } = args
      const temp = uuid()

      const friends = rootGetters[ 'Friends/friends' ]
      const { id: me, balance } = rootGetters[ 'Auth/me' ]

      const them = id => friends.find( f => f.requestor === id || f.requestee === id )
      const construct = id => id !== me && them( id )
      const participants = ids.map( construct ).filter( Boolean )

      //Add it instantly client side
      commit( 'APPEND_CONVERSATION', {
        id: temp,
        participants,
        updatedAt: new Date()
      })

      const price = rootGetters[ 'Prices/conversation' ]
      if ( price && ids.length > 2 ) Vue.set( rootState.Auth, 'balance', balance - price )

      const res = await axios.put( '/conversations', ids ).catch( console.log )

      const id = _.get( res, 'data' )
      if ( id ) {
        commit( 'UPDATE_CONVERSATION', { temp, id })
        return id
      }

      commit( 'DELETE_CONVERSATION', temp )
      if ( price && ids.length > 2 ) Vue.set( rootState.Auth, 'balance', balance )
    },

    async exists( _, participants ) {
      const { data } = await axios.post( '/conversations/exists', participants )
      return data || false
    }
  }
}